import React, { useState, useRef, useEffect } from 'react';
import logo from "../../assets/logo.png";
import { useNavigate } from 'react-router-dom';
import AudioCard from '../../components/ordernotify/AudioCard';
import AudioPlayer from '../../bell-sound.wav';
import { FaVolumeMute, FaVolumeUp } from 'react-icons/fa';

function OrderNotification() {
    const navigate = useNavigate();
    const [isMuted, setIsMuted] = useState(false);
    const audioRef = useRef(null);

    const redirectBack = () => {
        navigate("/ongoing-orders");
    };

    return (
        <div className="container-fluid vh-100">
            <div className="row">
                <div className="col">
                    <h5 className="mt-1" style={{ color: "#ffaa00" }}>
                        <img src={logo} width="30px" height="30px" alt="" />
                        <span className="ms-2">Mr.SinghsPizza</span>
                    </h5>
                </div>
            </div>
            <div className='row'>
                <AudioCard audioSrc={AudioPlayer} audioRef={audioRef} />
            </div>
        </div>
    );
}

export default OrderNotification;
